export const questionsArray = [
    {
        id: 1,
        question: 'How to install the extension?',
        paragraph: [`The extension can easily be downloaded from the chrome store using <a href='https://chrome.google.com/webstore/detail/dnnae-linkedin-automation/kgilfabddfgbgodjclngamckpdagbagp'>this link</a>.`,
                    `Please note that the extension only works on Google Chrome.`,
                    `If you do not have the Chrome browser, please install it from: <a href='https://www.google.com/chrome/'>https://www.google.com/chrome/</a>`],
    },{
        id: 2,
        question: 'How to create an account?',
        paragraph: [`1. Click on DNNae extension`,
                    `2. Click on the Sign-Up button on the bottom left-hand corner`,
                    `3. You will be directed to the main page where you can enter the email`,
                    `4. You can create your account by manually signing up or using Google, Microsoft account with us`,
            ]
    },{
        id: 3,
        question: 'Does it only work on Chrome?',
        paragraph: [`Yes, DNNae only works on Chrome. The extension can easily be downloaded from the chrome store using <a href='https://chrome.google.com/webstore/detail/dnnae-linkedin-automation/kgilfabddfgbgodjclngamckpdagbagp'>this link</a>.`,
                    `If you do not have the Chrome browser, please install it from: <a href='https://www.google.com/chrome/'>https://www.google.com/chrome/</a>`
            ]
    },{
        id: 4,
        question: 'How many Connection Requests can I send per day?',
        paragraph: [`You can send infinite connection requests using DNNae, we recommend you to pause after sending 
        50-60 connection requests to avoid any errors/limitations from LinkedIn.`,
            `You can also set a daily limit in DNNae to send the connection requests.`
            ]
    },{
        id: 5,
        question: 'What is the Daily Limit?',
        paragraph: [`The daily limit is the number of connection requests that you can send per day using DNNae. We 
        recommend setting a smaller daily limit in DNNae and slowly building up to a high value over weeks.`]
    },{
        id: 6,
        question: 'How can I adjust my daily limit?',
        paragraph: [`1. Click on DNNae extension`,
        `2. On the top right corner, click on the settings icon`,
        `3. Set your daily limit`,
        `4. Click Apply on the top right corner`]
    },{
        id: 7,
        question: 'Can I minimize the window while DNNae is working?',
        paragraph: [`Yes, you can minimize the window. If you edit the job criteria that already have some prospects 
        in them, you need to make sure that you do not close the tab that already has some prospects if you are running a job search.`]
    },{
        id: 8,
        question: 'Very Few Results Returned',
        paragraph: [`There is a way to fix this problem:`,
            `1.  Click on DNNae extension`,
            `2.  Click on the three vertical dots right next to the job that you have entered`,
            `3.  Click on the Edit Criteria`,
            `4.  Under the Important Skills section, reduce the number of must-have skills`,
            `5.  Add the additional titles so the tool can generate more search terms to find the potential candidate`,
            ]
    },{
        id: 9,
        question: 'How to create a job?',
        paragraph: [`1. Click on DNNae extension`,
            `2. Click on <strong>New Prospect Search</strong>`,
            `3. You will be directed to the web page where you can Paste or Compose your JD`,
            ]
    },{
        id: 10,
        question: 'How to edit the job?',
        paragraph: [`1. Click on DNNae extension`,
            `2. Click on the three vertical dots right next to the job that you have entered`,
            `3. Click on the <strong>Edit Criteria</strong>`,
            ]
    },{
        id: 11,
        question: 'How to delete a job?',
        paragraph: [`1. On DNNae extension`,
            `2. Click on the three vertical dots right next to the job that you have entered`,
            `3. Click on Delete Job`,
            ]
    },{
        id: 12,
        question: 'DNNae is not extracting the information correctly from my job',
        paragraph: [`The tool extracts the information from the Job Description that you have added. If it needs to 
        be refined you can have three options to filter it;`,
            `1. Edit extracted information`,
            `   =) If there is any information that needs to be corrected, you can do that after adding the job`,
            `2. Remove extracted information`,
            `   =) You can remove any information that you think is not necessary for the job`,
            `3. Add extracted information`,
            `   =) If you want to make some addition to the skills or to the titles you can do that under the Revised Criteria page`,
            ]
    },{
        id: 13,
        question: 'What are prospects?',
        paragraph: [`After running the job, profiles that come, as a result, are called prospects.`]
    },{
        id: 14,
        question: 'How to find prospects for your job?',
        paragraph: [`Follow the step by step guide to add the prospects for your job`,
            `1. Click on DNNae extension`,
            `2. Click on New Prospect Search`,
            `3. After adding all the requirements DNNae will redirect you to LinkedIn`,
            `4. Click on the DNNae extension`,
            `5. Click on <strong>Find and Rank</strong>`,
            `6. Let the job run for at least an hour to find the list of the prospects for the job you have entered`,
            ]
    },{
        id: 15,
        question: 'Why am I seeing people as ‘LinkedIn Members’?',
        paragraph: [`LinkedIn shows very little information about <strong>members</strong> who are out of our network. You can not 
        send them a connection request or view their profile.`]
    },{
        id: 16,
        question: 'How can I view prospects for my job?',
        paragraph: [`1. Click on DNNae extension`,
            `2. Find the job that you’re trying to find the prospects for`,
            `3. Click on View Prospects`]
    },{
        id: 17,
        question: 'Why am I seeing ‘No Prospects Found’?',
        paragraph: [`After adding a job to DNNae, please click on <strong>Find and Rank</strong> to start the search. Once the tool 
        starts running you will be able to see the prospects under the <strong>View Prospects option</strong>.`]
    },{
        id: 18,
        question: 'How can I see the prospect score?',
        paragraph: [`1. Click on DNNae extension`,
            `2. Click on View Prospect`,
            `3. Prospect score is mentioned under the candidate’s name`,
            ]
    },{
        id: 19,
        question: 'What do prospect scores represent?',
        paragraph: [`Every profile has its attribute on which we rank the profiles. The higher the score is the higher 
        it will be ranked on the list of potential candidates.`]
    },{
        id: 20,
        question: 'How to download the profile?',
        paragraph: [`1. Select the profiles that you wish to download`,
            `2. After selecting, you can see three options popped up on the top of the page`,
            `3. Click on the <strong>Download as CSV</strong>`]
    },{
        id: 21,
        question: 'What is the rescoring of prospects?',
        paragraph: [`If you edit the job criteria which already have some prospects in them, DNNae will rescore the prospects 
        based on the updated job criteria.`]
    },{
        id: 22,
        question: 'What are Tribes?',
        paragraph: [`Tribes allow you to add team members of your organization to your job search. It will help you add more 
        prospects to your job. You can give them certain permission to add or provide feedback for the ideal candidates.`]
    },{
        id: 23,
        question: 'Can I add a team member who is not a part of DNNae?',
        paragraph: [`Yes, you can invite a non DNNae team member to your job as a tribe member, he/she can sign up through the 
        invite link.`]
    },{
        id: 24,
        question: 'Why are some of the prospects showing in the previously messaged tab?',
        paragraph: [`You have already sent them a message on a previously added job.`]
    },{
        id: 25,
        question: 'Is there any way I can save the advanced filters?',
        paragraph: [`Yes, after selecting the desired filters, you can click on the <strong>No Template</strong> and add the custom template so you 
        can use it in the future for the same job.`,
            `<strong>How to add a tribe member on a new job?</strong>`,
            `After clicking on the “New prospect search”. The option to search for the member you wish to add on the job is shown. 
            Enter the name or email address of the member you wish to add and select from the drop down menu. Then, click on the 
            add on the top right.`
            ]
    },{
        id: 26,
        question: 'What are ideal prospects?',
        paragraph: [`Any candidate that you think is the best fit for the job that you are searching for is called an ideal prospect.`]
    },{
        id: 27,
        question: 'How to add an ideal prospect?',
        paragraph: [`To add the ideal prospect, go to their linkedin profile and copy their profile URL. Paste that link onto the 
        section under the ideal prospect and click on the plus icon right next to it.`]
    },{
        id: 28,
        question: 'How to add a comment?',
        paragraph: [`Right click anywhere on the prospect profile and you will see the option to add/view the comments. Click on add 
        a comment and a sidebar will appear for the comment section.`]
    },{
        id: 29,
        question: 'What can be added in the comments section?',
        paragraph: [`You can add text, create mentions and tags for the existing tribe members, as well as send voice notes to your 
        tribe using the comments option.`]
    },{
        id: 30,
        question: 'How to add a tribe member to an existing job?',
        paragraph: [`Go to extension, click on view prospects for the job that you wish to add tribe members to. Click on the tribe tab 
        at the top. Click on edit on the top right hand side. Add tribe members. Click save.`]
    },{
        id: 31,
        question: 'What are company preferences?',
        paragraph: [`Company preferences tab allows you to score candidates that belong to the preferred firms in the same or multiple 
        industries. It allows for better selection of candidates from the competitor companies that have the same background or that you 
        would be willing to hire from.`]
    },{
        id: 32,
        question: 'How to add company preferences to a new job?',
        paragraph: [`Click on the DNNae extension, and then select “new prospect search”. After selecting the tribe or skipping the tribe 
        section, DNNAE takes you to the company preferences page. Here you add the company that you are hiring for and then check mark the
        companies that you prefer to see candidates from. You can either select the companies from the list or add the company manually too. 
        Click on continue once done.`]
    },{
        id: 33,
        question: 'How to add company preferences to an existing job?',
        paragraph: [`You can always go back to edit your job criteria to add company preferences to better run the job. In order to do so, 
        simply click on the Job Setup tab at the top. Once done, select the company preferences tab from the left. Click on the Edit button 
        on the top right. Once done, simply add the company preference/s in the available tab and select the save option.`]
    },{
        id: 34,
        question: 'How to view prospect results?',
        paragraph: [`Once the extension is running, the prospects that our tool has found are marked on the top right corner of the screen 
        in the “View results” tab. You can click on the “View results” tab to view the prospects.`]
    },{
        id: 35,
        question: 'Is there a way to delete a comment?',
        paragraph: [`You can delete the comment that you have added to a profile. In order to
        do so, simply select the comment. On top of the comment on the right hand side of the page, you will observe a bin icon. Select the 
        bin icon to remove/permanently delete your comment.`]
    },{
        id: 36,
        question: 'Can you edit your comment?',
        paragraph: [`There is no option to edit your comment.`]
    },{
        id: 37,
        question: 'Who can see your comment on a prospect profile?',
        paragraph: [`Only tribe members can see the comments.`]
    },{
        id: 38,
        question: 'How to save a prospect profile?',
        paragraph: [`On the prospect page, there is an icon of a bookmark on the top right side of the profile. Click on the bookmark icon 
        and the profile will be saved to be viewed later on.`]
    },{
        id: 39,
        question: 'How to download a prospect profile?',
        paragraph: [`To download a prospect profile or multiple profiles, simply select the check boxe/s on the top left hand side of the 
        profile. Once the profiles are selected, click on the Download icon at the top. You will be able to download the profiles in a CSV format.`]
    },{
        id: 40,
        question: 'How to send a message to a prospect?',
        paragraph: [`On the left side of the profile on the prospect page, there is  a small box. Once you click on it, the message icon 
        shows on the top. Clicking on the icon takes you to the screen where you can set up if you want to add a follow up message if the 
        prospect does not reply back to you. You can also select the follow up days after which the message should be sent. You can click 
        on the “Li message” option in the middle and it will allow you to save the message template that you wish to send to your prospects. 
        To send a message to the candidates just click on publish.`]
    },{
        id: 41,
        question: 'How to request setting up a meeting with a prospect?',
        paragraph: [`You can directly request setting up a meeting with your desired prospect. Simply select the check boxe/s on the top left 
        hand side of the profile. Once the profiles are selected, click on the “Request Meeting” button at the top. You can schedule the meeting 
        via Outlook or Google Meets.`]
    },{
        id: 42,
        question: 'Can you delete a prospect?',
        paragraph: [`In the case you do not want to view a specific candidate/s in your prospect results list, simply select the check boxe/s on 
        the top left hand side of the profile. Once the profiles are selected, click on the trash bin button at the top. This would archive the 
        profile/s, and they would be visible in the archived section.`]
    },{
        id: 43,
        question: 'Is direct mailing possible via Dnnae?',
        paragraph: [`Direct emailing the candidates is possible in the current Dnnae interface, however it is available in the beta mode. It is 
        not recommended as it is in the testing mode, however the new Dnnae release would have the proper emailing option available.`]
    },{
        id: 44,
        question: 'What is the Pre-Eval form?',
        paragraph: [`This option allows you to create a questionnaire that you would like the prospect to answer. Then you can review the questionnaire 
        before going into a meeting with them.`]
    },{
        id: 45,
        question: 'What are the buttons appearing on the left hand side of the prospects page?',
        paragraph: [`<strong>a. Added:</strong>`,
            `The total number of prospects that have been searched by the DNNae.`,
            `<strong>b. Messaged:</strong>`,
             `<strong>..... 1. Connect Messaged:</strong>`,
            `The number of prospects that you have sent out to a connection message.`,
             `<strong>..... 2. First Follow Up:</strong>` ,
            `The number of prospects who accepted the connection request but did not respond back to our message.`,
             `<strong>..... 3. Second Follow Up:</strong>`,
            `The number of prospects who did not respond back to the first follow up message.` ,
            `<strong>c. Replied:</strong>` ,
            `The number of prospects who responded to our message.`,
            `<strong>d. Meeting Confirmed:</strong>` ,
            `The number prospects who responded back to the meeting request we sent and selected a meeting slot on your calendar`,
            `<strong>e. Archived:</strong>`,
            `The number of prospects that we have deleted from the result list.`,
            `<strong>f. Downloaded</strong>:` ,
            `The number of prospects that we have downloaded from the result list.` ,
            `<strong>g. Previously Messaged</strong>:` ,
            `The prospects that we have already messaged.`,
            ]
    },{
        id: 46,
        question: 'What are filters?',
        paragraph: [`On the main prospects page, you have the option to further narrow down the result based on some specific options.`]
    },{
        id: 47,
        question: 'What are different available filters available on the dashboard?',
        paragraph: [`<strong>1. Skills:</strong>`,
            `This allows us to eliminate the profiles that either do not have a specific skill that we entered or have the specific skill that we selected under filters.`, 
            `<strong>2. Educational Qualification:</strong>`,
            `This allows us to eliminate the profiles that either have or do not have a specific educational degree. You can also strictly match this filter.`,
            `<strong>3. Educational Institutions:</strong>`,
            `This allows us to eliminate the profiles that either have or do not have studied from a particular institution.`,
            `<strong>4. Job Title:</strong>` ,
            `This allows us to eliminate the profiles that either have or do not have a specific job title in their current company or past companies they have worked with. You can also strictly match this filter.`,
            `<strong>5. Company:</strong>` ,
            `This allows us to eliminate the profiles that either have or do not have a specific company in their current or previous experience.`,
            `<strong>6. Experience:</strong>` ,
            `This allows you to view prospects based on the selected experience criteria. You can also strictly lock this if you like to view single or multiple experiences only.`,
            `<strong>7. Location</strong>:` ,
            `This allows you to sort prospects based on their current location. You can also strictly match this filter.`,
            `<strong>8. Industry:</strong>` ,
            `This allows you to filter candidates based on their added industry experience.` ,
            `<strong>9. Company Size:</strong>` ,
            `This filter allows you to view the enrolled prospects according to the current company size they are working with.` ,
            `<strong>10. Visa Type:</strong>` ,
            `This sorts prospects based on their visa type i.e. green card, citizen, H1B, and OPT.`,
            ]
    },{
        id: 48,
        question: 'Can the prospect results be sorted in any way?',
        paragraph: [`We can sort the search results as per our preference. We can sort the list based on age, Ethnicity, gender, experience, education etc.`]
    },{
        id: 49,
        question: 'Is there a way to differentiate your prospect list?',
        paragraph: [`Yes, you can separate the prospects in the following options:`,
           `<strong>a. Saved:</strong>` ,
           `This option shows the profiles that you have saved.` ,
           `<strong>b. Not saved:</strong>`,
           `This option shows the unsaved profiles from the total results.`,
           `<strong>c. Downloaded:</strong>`,
           `The option shows the profiles that you have downloaded.`,
           `<strong>d Commented:</strong>`,
           `The profiles on which you have added the comments.`,
           `<strong>e. Recommended:</strong>`,
           `The profiles that our AI suggests which it thinks are the best match based on the criteria and ideal prospects that you set up.`,
           ]
    }

]